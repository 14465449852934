<template>
    <div class="filter-search"
         id="filter-search-container">
        <div class="wrapper">
            <h2 v-if="title"
                class="xl-title"
                v-html="title" />
            <div class="expand-filters">
                <button id="expand-filters"
                        class="xxs-title"
                        v-html="`Expand Filters`" />
            </div>
            <div class="inner">
                <div class="filters">
                    <div v-if="filterByLabel"
                         class="title">
                        <span class="xs-title"
                              v-html="filterByLabel" />
                    </div>
                    <div class="content">
                        <div class="top">
                            <FilterDropdown v-for="({items, label, taxName}, index) in filters"
                                            ref="filterBox"
                                            :key="index"
                                            :index="index"
                                            :options="items"
                                            :taxonomy="taxName"
                                            @updateQuery="getDropdownValues"
                                            :title="label" />
                        </div>
                        <div class="bottom">
                            <button v-if="applyFilterText"
                                    id="apply-filters"
                                    class="btn brown"
                                    @click="applyQuery"
                                    v-html="applyFilterText" />
                            <div class="clear-filters">
                                <button id="clear-filters"
                                        @click="clearFilters"
                                        v-html="clearFilterText" />
                            </div>
                        </div>
                    </div>
                
                </div>
                <div class="search">
                    <label for="search"
                           v-html="searchLabel" />
                    <fieldset class="search-field">
                        <input type="text"
                               v-model="searchText"
                               id="search"
                               name="search"
                               @keydown.enter.prevent="setSearchText"
                               :placeholder="searchPlaceholder"
                               :aria-placeholder="searchPlaceholder" />
                        <button type="button"
                                class="search-btn"
                                @click.prevent="setSearchText"
                                @keydown.enter.prevent="setSearchText">
                            <Arrow />
                        </button>
                    </fieldset>
                    <div class="clear-search">
                        <button id="clear-search"
                                @click="clearSearch"
                                @keydown.enter="clearSearch"
                                v-html="clearSearchText" />
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</template>

<script type="application/javascript">
    /**
     * JS Plugins
     */
    import Combobo from "combobo";
    
    /**
     * Vue Scripts
     */
    import {EventBus} from "../../Util/event-bus.js";
    
    /**
     * Assets
     */
    
    /**
     * Vue Components
     */
    import Arrow from "../Icons/Arrow.vue"; // Created by FE as standalone
    import FilterDropdown from "./FilterDropdown.vue";
    import mixins from "../../Util/mixins.js";
    
    export default {
        props: {
            applyFilterText: {
                type: String,
                default: null,
            },
            clearFilterText: {
                type: String,
                default: null,
            },
            clearSearchText: {
                type: String,
                default: null,
            },
            filterByLabel: {
                type: String,
                default: null,
            },
            filters: {
                type: [Array, Object],
                default: null,
            },
            searchLabel: {
                type: String,
                default: "",
            },
            searchPlaceholder: {
                type: String,
                default: null,
            },
            title: {
                type: String,
                default: null,
            }
        },
        data: () => ({
            defaultOptions: {
                options: ".option", // qualified within `list`
                openClass: "open",
                activeClass: "active",
                selectedClass: "selected",
                useLiveRegion: false,
                multiselect: true,
                noResultsText: null,
                selectionValue: selecteds => `(${selecteds.length}) ${selecteds.map(s => s.innerText.trim()).join(", ")}`,
                optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
                filter: "contains" // 'starts-with', 'equals', or funk
            },
            filterBoxes: [],
            selectedCategories: [],
            postType: ARCHIVE.postType,
            searchText: "",
        }),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {
            console.log(this.$refs.filterBox);
            [...this.$refs.filterBox].map(filter => this.setupCombo(filter.$el));
        },
        methods: {
            /**
             * Grabs the Dropdown Filters emitted and sends them to Vuex
             * @param values
             */
            getDropdownValues(values) {
                const {terms} = values;
                this.$store.commit("setUpQuery", values);
                this.selectedCategories = [];
                let newCats = [];
                this.filters.map(({items}) => {
                    newCats = items.filter(item => terms.indexOf(item.slug) > -1);
                });
                
                if (newCats.length) this.selectedCategories = newCats;
            },
            /**
             * Sets the search text and submits it to Vuex
             */
            setSearchText() {
                this.$store.commit("setUpQuery", {search: true, searchTerm: this.searchText});
                this.commitState({searchTriggered: true, searchText: this.searchText});
            },
            /**
             * Makes our API call
             */
            applyQuery() {
                let categories = this.defaultCategories;
                if (this.selectedCategories.length) categories = this.selectedCategories;
                
                this.commitState({categories, searchTriggered: true});
            },
            /**
             * Resets only the search text
             */
            clearSearch() {
                this.searchText = "";
                this.$store.commit("setUpQuery", {search: false});
                this.commitState({searchTriggered: true, searchText: ""});
            },
            /**
             * Iterates through the filterBoxes defined in the setupCombo method and clears them.
             * Then sends a commit to the store to clear the taxonomy params
             */
            clearFilters() {
                // Reset the filter arrays
                EventBus.$emit("reset-filters");
                // Reset Vuex state
                this.$store.commit("resetQuery", {postType: this.postType, postsNotIn: this.idsToOmit});
                // Reset combobo
                this.filterBoxes.map(filterBox => filterBox.reset());
                this.commitState({categories: this.defaultCategories});
                this.resetApi();
            },
            resetApi() {
                this.$emit("getPagedItems", {
                    api: this.apiUrl,
                    query: this.query,
                });
            },
            /**
             * Initiates the Combobo JS plugin and assigns it to the FilterDropdowns by their $ref
             *
             * @param elm The Vue Component to set up with Combobo
             */
            setupCombo(elm) {
                if (elm && elm.childNodes.length) {
                    const inputId = elm.querySelector("[id^=\"filter-box-\"]");
                    const listId = elm.querySelector("[id^=\"listbox-filter-\"]");
                    this.filterBoxes.push(new Combobo({
                        input: `#${inputId.getAttribute("id")}`,
                        list: `#${listId.getAttribute("id")}`,
                        ...this.defaultOptions,
                    }));
                }
            },
        },
        computed: {
            defaultCategories() {return this.$store.state.defaultCategories;},
            idsToOmit() {return this.$store.state.idsToOmit;},
            paged() {return this.$store.state.paged;},
            query() {return this.$store.state.query;},
        },
        watch: {},
        mixins: [mixins],
        components: {Arrow, FilterDropdown},
        name: "FilterBar"
    };
</script>

<style lang="scss" scoped>
    input {
        margin-top: 0 !important;
    }
    .search {
        label {
            padding-bottom: 0;
            margin-bottom:  0.625rem;
        }
    }
    .search-field {
        display:         flex;
        flex-flow:       row nowrap;
        align-items:     center;
        justify-content: space-around;
        margin:          0;
        border:          0;
        padding:         0 0 1.25rem;
        position:        relative;
        input {
            flex: 0 0 auto;
        }
        button {
            flex:     0 0 20px;
            position: absolute;
            //height: 0.75rem;
            width:    20px;
            right:    1.25rem;
            //pointer-events: none;
        }
    }
</style>
