/**
 * Used for building out the sites navigation
 * @param currentState
 * @param data
 * @constructor
 */
export function buildNavigation(currentState, {viewport, data}) {
    if (viewport === "desktop") currentState.desktopNav = data;
    if (viewport === "mobile") currentState.mobileNav = data;
}

/**
 * Sets the state regardless of the type. Don't use for query
 *
 * @param currentState
 * @param params
 */
export function setState(currentState, params) {
    Object.keys(params).map(key => currentState[key] = params[key]);
}

/**
 * Used to update the API call with IDs to not include in the query.
 * This is agnostic to a component
 *
 * @param currentState The store state
 * @param ids           An array of IDs to omit from the search query
 */
export function setPostsToOmit(currentState, {ids}) {
    currentState.idsToOmit = ids.length && ids;
}

/**
 * Sets up the query for the Vuex API call to grab posts
 *
 * @param currentState
 * @param params
 */
export function setUpQuery(currentState, params) {
    const {postsNotIn, postType, search, searchTerm, taxonomy, terms} = params;
    if (taxonomy) {
        currentState.query[taxonomy] = terms;
    }
    if (search) {
        currentState.query.s = searchTerm;
    }
    else if (!search) {
        delete currentState.query.s;
    }
    if (postType) {
        currentState.query.postType = postType;
    }
    if (postsNotIn) {
        currentState.query.postsNotIn = postsNotIn;
    }
}

/**
 * Resets the Vuex query state
 * @param currentState
 * @param params
 */
export function resetQuery(currentState, params) {
    currentState.query = params;
}

/**
 * Sets the Vuex posts state
 * @param currentState
 * @param posts
 */
export function itemList(currentState, {posts}) {
    currentState.posts = posts;
}
