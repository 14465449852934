<template>
    <div v-if="options.length || (typeof options === 'object' && objNotEmpty(options))"
         class="filter__dropdown">
        <div class="combo-wrap">
            <label v-html="title" />
            <input type="text"
                   class="combobox slide-caption"
                   :id="`filter-box-${index}`"
                   :name="`filter-${index}`"
                   autocomplete="off"
                   placeholder="Select" />
            
            <div :id="`listbox-filter-${index}`"
                 :class="`listbox filter-${index} slide-caption multi`">
                <div v-for="({ID, name, slug}) in options"
                     :key="ID"
                     class="option"
                     :data-slug="slug"
                     @click="addToFilter(slug)"
                     v-html="name" />
            </div>
        </div>
    </div>
</template>

<script type="application/javascript">
    /**
     * Vue Scripts
     */
    import {EventBus} from "../../Util/event-bus.js";
    import mixins from "../../Util/mixins.js";
    
    export default {
        props: {
            index: {
                type: Number,
                default: 0,
            },
            options: {
                type: [Array, Object],
                default: null,
            },
            placeholder: {
                type: String,
                default: "Select",
            },
            taxonomy: {
                type: String,
                default: "",
            },
            title: {
                type: String,
                default: null,
            }
        },
        data: () => ({
            collectedValues: [],
        }),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {
            EventBus.$on("reset-filters", () => this.collectedValues = []);
        },
        methods: {
            addToFilter(value) {
                const index = this.collectedValues.indexOf(value);
                if (index > -1) {
                    this.collectedValues.splice(index, 1);
                }
                else {
                    this.collectedValues.push(value);
                }
                this.$emit("updateQuery", {taxonomy: this.taxonomy, terms: this.collectedValues});
                this.$emit("checkQuery");
            }
        },
        watch: {},
        components: {},
        mixins: [mixins],
        name: "FilterDropdown"
    };
</script>

<style lang="scss" scoped>

</style>
