<template>
    <div class="events">
        <div class="wrapper">
            <h2 v-if="title"
                class="xl-title center-aligned"
                v-html="title" />
            <!-- If grabbing posts, show loading -->
            <Loading :is-loading="!!loading"
                     color="#3762ae" />
            <div class="events-grid">
                <Post v-if="!loading && posts.length"
                      v-for="({date, id, image, permalink, tags, title}) in posts"
                      :key="id"
                      :date="date"
                      :image="image"
                      :permalink="permalink"
                      :tag="tags"
                      :title="title" />
                <NoResults v-if="!loading && !posts.length"
                           :no-results-text="noResults" />
            </div>
        </div>
    </div>
</template>

<script type="application/javascript">
    
    import Post from "../Blocks/Post.vue";
    import NoResults from "../Blocks/NoResults.vue";
    import Loading from "../Blocks/Loading.vue";
    
    export default {
        props: {
            title: {
                type: String,
                default: null,
            },
        },
        data: () => ({
            noResults: ARCHIVE.noResults,
        }),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {},
        methods: {},
        computed: {
            loading() {return this.$store.state.loading},
            posts() {return this.$store.state.posts}
        },
        watch: {},
        components: {Loading, NoResults, Post},
        name: "Listing"
    };
</script>

<style lang="scss" scoped>
</style>
