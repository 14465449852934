<template>
    <div class="archive-container">
        <FilterBar :apply-filter-text="filterBar.apply_filter_text"
                   :clear-filter-text="filterBar.clear_filter_text"
                   :clear-search-text="filterBar.clear_search_text"
                   :filter-by-label="filterBar.filter_by_label"
                   :filters="filters"
                   :search-label="filterBar.search_label"
                   :search-placeholder="filterBar.search_placeholer"
                   :title="title"
                   @getPagedItems="getPagedItems" />
        <PostPagination @getPagedItems="getPagedItems" />
        
        <Listing />
        
        <PostPagination @getPagedItems="getPagedItems" />
    </div>

</template>

<script type="application/javascript">
    
    /**
     * Vue Components
     */
    import FilterBar from "./Archive/FilterBar.vue";
    import Listing from "./Archive/Listing.vue";
    import PostPagination from "./Archive/PostPagination.vue";
    import mixins from '../Util/mixins';
    
    export default {
        props: {},
        data: () => ({
            filterBar: ARCHIVE.filter || [],
            filters: [
                ARCHIVE.types,
                ARCHIVE.topics,
            ],
            postType: ARCHIVE.postType,
            title: ARCHIVE.title || "",
            topics: ARCHIVE.topics || [],
            types: ARCHIVE.types || [],
        }),
        beforeCreate() {},
        created() {
            this.$store.commit("setState", {apiUrl: ARCHIVE.apiUrl, paged: 1, maxPages: 0});
            this.$store.commit("setUpQuery", {postType: this.postType});
            this.$store.dispatch("getPagedItems", {
                api: this.apiUrl,
                paged: this.paged,
                query: this.query,
            });
        },
        beforeMount() {},
        mounted() {},
        methods: {
            getPagedItems() {
                this.$store.dispatch("getPagedItems", {
                    api: this.apiUrl,
                    paged: this.paged,
                    query: this.query,
                });
            }
        },
        computed: {
            apiUrl() {return this.$store.state.apiUrl;},
            idsToOmit() {return this.$store.state.idsToOmit;},
            paged() {return this.$store.state.paged;},
            query() {return this.$store.state.query;},
            searchTriggered() {return this.$store.state.searchTriggered;},
        },
        watch: {
            searchTriggered(triggered) {
                if (triggered) this.getPagedItems();
                this.commitState({searchTriggered: false});
            }},
        mixins: [mixins],
        components: {FilterBar, Listing, PostPagination},
        name: "NewsEvents"
    };
</script>

<style scoped>

</style>
