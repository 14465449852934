import Vue from "vue";
import Vuex from "vuex";

import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        apiUrl: null,
        categories: [],
        defaultCategories: [],
        idsToOmit: null,
        loadMore: "",
        loading: true,
        maxPages: 0,
        paged: 1,
        posts: [],
        ppp: 10,
        query: {},
        searchText: "",
        searchTriggered: false,
        total: 0,
    },
    mutations,
    actions,
    getters,
});
