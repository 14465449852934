<template>
    <div v-if="maxPages > 1"
         class="pagination">
        <div class="wrapper">
            <div class="pagination-block">
                <div class="inner">
                    <Pagination
                        :active-class="pagOpts.activeClass"
                        :break-view-class="pagOpts.breakViewClass"
                        :break-view-text="pagOpts.breakViewText"
                        :click-handler="goToPage"
                        :container-class="pagOpts.containerClass"
                        :margin-pages="1"
                        :next-class="pagOpts.nextClass"
                        :next-link-class="pagOpts.linkClass"
                        :next-text="pagOpts.next"
                        :page-count="maxPages"
                        :page-range="3"
                        :prev-class="pagOpts.prevClass"
                        :prev-link-class="pagOpts.linkClass"
                        :page-link-class="pagOpts.linkClass"
                        :prev-text="pagOpts.prev"
                        :value="currentPage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script type="application/javascript">
    /**
     * Vue Plugins
     */
    import Pagination from "vuejs-paginate";
    
    /**
     * Vue Scripts
     */
    import mixins from "../../Util/mixins.js";
    
    export default {
        props: {
            page: {
                type: Number,
                default: 1,
            },
        },
        data: () => ({
            pagOpts: {
                next: `<i class="fas fa-chevron-right"></i><span class="screen-reader-text">Next Page</span>`,
                prev: `<i class="fas fa-chevron-left"></i><span class="screen-reader-text">Previous Page</span>`,
                activeClass: "active-page",
                breakViewText: ".",
                linkClass: "page-number",
                nextClass: "pagination-button-next",
                prevClass: "pagination-button-prev",
                breakViewClass: "more",
                containerClass: "no-bullets",
            },
        }),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {},
        methods: {
            goToPage(pageNum) {
                if (event !== undefined && event.currentTarget.classList.contains("page-number")) {
                    this.$store.commit("setState", {paged: pageNum});
                    this.$emit("getPagedItems");
                    this.scrollToEvent($("#filter-search-container"));
                }
            },
        },
        computed: {
            currentPage() {return this.$store.state.paged;},
            maxPages() {return this.$store.state.maxPages;},
        },
        components: {Pagination},
        mixins: [mixins],
        name: "PostPagination"
    };
</script>

<style lang="scss" scoped>

</style>
