/**
 * A file that contains most of the Vue Components and logic
 */
import Vue from "vue";
import {store} from "./Vuex/store.js";
import { MediaQueries } from "vue-media-queries";
import VueAccessibleModal from "vue-accessible-modal";

window.Vue = Vue;
const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);

/** Vue Accessibility Default Options **/
const modalOptions = {
    transition: 'fade',
};
Vue.use(VueAccessibleModal, modalOptions);

Vue.config.productionTip = false;

const navigationElm = document.getElementById("vue-navigation-container");
const newsEventsSearchElm = document.getElementById("vue-news-events-search");
const faqElm = document.getElementById("vue-faq-container");

const VueInstance = new Vue({
    store,
    mediaQueries: mediaQueries,
    created() {
        if (typeof ARCHIVE !== 'undefined') {
            this.$store.commit('setPostsToOmit', {ids: ARCHIVE.postIdsToOmit});
        }
    }
});

if (newsEventsSearchElm !== null) {
    const newsEventsContainer = Vue.component('NewsEventsContainer', require('./Components/NewsEvents.vue').default);
    const newsEventsElm = new Vue({
        el: '#vue-news-events-search',
        mediaQueries: mediaQueries,
        store,
        render: h => h(newsEventsContainer),
    })
}

if (faqElm !== null) {
    const faqContainer = Vue.component('Faq', require('./Components/FAQ.vue').default);
    const faqContainerElm = new Vue({
        el: "#vue-faq-container",
        mediaQueries: mediaQueries,
        store,
        render: h => h(faqContainer),
    })
}
