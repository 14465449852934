<template>
    <div v-if="loading"
         class="loading">
        <svg id="L4"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             width="60px"
             viewBox="0 0 60 60"
             enable-background="new 0 0 0 0"
             xml:space="preserve">
            <g>
            <circle fill="#333" stroke="none" cx="6" cy="50" r="6">
                <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
            </circle>
                <circle fill="#333" stroke="none" cx="26" cy="50" r="6">
                <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
            </circle>
                <circle fill="#333" stroke="none" cx="46" cy="50" r="6">
                <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
            </circle>
            </g>
        </svg>
    </div>
</template>

<script type="application/javascript">
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({}),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {},
        methods: {},
        computed: {},
        watch: {},
        components: {},
        name: "DotLoading"
    };
</script>

<style lang="scss" scoped>
    .loading {
        display:         flex;
        justify-content: center;
        align-items:     center;
        height:          100%;
        width:           100%;
    }
</style>
