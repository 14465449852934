<template>
    <div v-if="isLoading"
         class="loading">
        <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
        <!-- https://samherbert.net/svg-loaders/ -->
        <!-- https://github.com/SamHerbert/SVG-Loaders/blob/master/svg-loaders/puff.svg?short_path=288de9e -->
        <svg width="150" height="150" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" :stroke="color">
            <g fill="none" fill-rule="evenodd" stroke-width="2">
                <circle cx="22" cy="22" r="1">
                    <animate attributeName="r"
                             begin="0s" dur="1.8s"
                             values="1; 20"
                             calcMode="spline"
                             keyTimes="0; 1"
                             keySplines="0.165, 0.84, 0.44, 1"
                             repeatCount="indefinite" />
                    <animate attributeName="stroke-opacity"
                             begin="0s" dur="1.8s"
                             values="1; 0"
                             calcMode="spline"
                             keyTimes="0; 1"
                             keySplines="0.3, 0.61, 0.355, 1"
                             repeatCount="indefinite" />
                </circle>
                <circle cx="22" cy="22" r="1">
                    <animate attributeName="r"
                             begin="-0.9s" dur="1.8s"
                             values="1; 20"
                             calcMode="spline"
                             keyTimes="0; 1"
                             keySplines="0.165, 0.84, 0.44, 1"
                             repeatCount="indefinite" />
                    <animate attributeName="stroke-opacity"
                             begin="-0.9s" dur="1.8s"
                             values="1; 0"
                             calcMode="spline"
                             keyTimes="0; 1"
                             keySplines="0.3, 0.61, 0.355, 1"
                             repeatCount="indefinite" />
                </circle>
            </g>
        </svg>
    </div>
</template>

<script type="application/javascript">
    export default {
        props: {
            isLoading: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: "#333333",
            }
        },
        name: "Loading"
    };
</script>

<style lang="scss" scoped>
    .loading {
        display:         flex;
        justify-content: center;
        align-items:     center;
        height:          100%;
        width:           100%;
    }
</style>
