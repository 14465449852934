<template>

    <div class="accordion-item">
        <button :id="id"
         :class="`accordion-trigger ${isOpen ? ' open' : ''}`"
         :aria-controls="`sect-${id}`"
         :aria-expanded="`${isOpen ? 'true' : 'false'}`"
         ref="faqItem"
         @click="toggleItem">
            <span v-html="question" />
            <span class="accordion-icon"></span>
        </button>

        <div :id="`sect-${id}`"
            :class="`accordion-panel`"
            :aria-labelledby="`accordion-${id}`"
            :aria-hidden="`${isOpen ? 'false' : 'true'}`">

            <div class="wrap">
                <div v-html="answer" />

                <div class="share">
                    <button class="copy-target btn btn-secondary"
                            ref="copyTarget"
                            @click="copyLink"
                            @mouseenter="toggleClickToCopy(true)"
                            @mouseleave="toggleClickToCopy(false)"
                            :data-clipboard-text="`${shareUrl}`">
                        <span class="copy-bold"
                            v-html="`Share Question`" />
                    </button>
                    <div class="copy-link"
                        ref="copyHoverText">
                        <span class="caption"
                            v-html="`Click to copy link to clipboard`" />
                    </div>
                    <div class="link-copied"
                        ref="copiedHoverText">
                        <span class="caption"
                            v-html="`Link has been copied!`" />
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script type="application/javascript">
    /**
     * JS Plugins
     */
    
    /**
     * Vue Scripts
     */
    import mixins from "../../Util/mixins.js";
    
    export default {
        props: {
            answer: {
                type: String,
                default: "",
            },
            id: {
                type: String,
                default: "",
            },
            index: {
                type: Number,
                default: false,
            },
            question: {
                type: String,
                default: "",
            },
            tocopylink: {
                type: String,
                default: "",
            },
            shareUrl: {
                type: String,
                default: "",
            }
        },
        data: () => ({
            isOpen: false,
        }),
        beforeCreate() {},
        created() {
            // this.isOpen = this.index === 0;
        },
        beforeMount() {},
        mounted() {},
        methods: {
            /**
             * This event handles the FAQ items active state
             */
            toggleItem() {
                this.isOpen = !this.isOpen;
            },
            /**
             * This event handles the click to copy functionality
             */
            copyLink() {
                this.toggleClickToCopy(false);
                this.copyText(this.$refs.copyTarget.dataset.clipboardText)
                    .then(
                        () => this.copiedEvents(this.$refs.copiedHoverText),
                        err => console.error(err)
                    );
            },
            /**
             * This event handles the state of the click to copy text
             * @param {boolean} visible
             */
            toggleClickToCopy(visible) {
                if (visible) this.$refs.copyHoverText.classList.add("visible");
                else this.$refs.copyHoverText.classList.remove("visible");
            }
        },
        computed: {},
        watch: {},
        mixins: [mixins],
        components: {},
        name: "FAQItem"
    };
</script>

<style lang="scss" scoped>

</style>
