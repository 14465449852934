<template>
    <section id="filter-search"
             class="faq-container"
             v-if="categories.length">
        <FilterBar :apply-filter-text="filterBar.apply_filter_text"
                   :clear-filter-text="filterBar.clear_filter_text"
                   :clear-search-text="filterBar.clear_search_text"
                   :filter-by-label="filterBar.filter_by_label"
                   :filters="filters"
                   :search-label="filterBar.search_label"
                   :search-placeholder="filterBar.search_placeholer" />
        
        <FAQCategory v-for="({ID, name}, index) in categories"
                     :key="index"
                     :category-id="ID"
                     :category-title="name"
                     :index="index"
                     :resets-search-trigger="(index + 1) === categories.length" />

    </section>
</template>

<script type="application/javascript">
    /**
     * Vue Scripts
     */
    import mixins from "../Util/mixins.js";
    
    /**
     * Vue Components
     */
    import FilterBar from "./Archive/FilterBarFaq.vue";
    import FAQCategory from "./FAQ/FAQCategory.vue";
    
    export default {
        props: {},
        data: () => ({
            filterBar: ARCHIVE.filter.filter_bar_labels || [],
            filters: [
                ARCHIVE.categories,
            ],
            postType: ARCHIVE.postType,
        }),
        beforeCreate() {},
        created() {
            const {apiUrl, categories, ppp, loadMore} = ARCHIVE;
            this.commitState({
                apiUrl,
                categories: categories.items,
                defaultCategories: categories.items,
                loadMore,
                ppp,
            });
            this.$store.commit("setUpQuery", {postType: this.postType});
        },
        beforeMount() {},
        mounted() {},
        methods: {
            //  getPagedItems() {
            //     this.$store.dispatch('getPagedItems', {
            //         api: this.apiUrl,
            //         paged: this.paged,
            //         query: this.query,
            //     });
            // }
        },
        computed: {
            categories() {return this.$store.state.categories;},
        },
        watch: {},
        mixins: [mixins],
        components: {FAQCategory, FilterBar},
        name: "FAQ"
    };
</script>

<style scoped>

</style>
