<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 26" preserveAspectRatio="none">
        <path d="M.136 10.834h30.702l-7.757-7.778L26.136.001l13 13-13 13-3.055-3.055 7.757-7.778H.136z"
              fill-rule="evenodd" />
    </svg>
</template>

<script type="application/javascript">
    export default {
        props: {},
        data: () => ({}),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {},
        methods: {},
        computed: {},
        watch: {},
        components: {},
        name: "Arrow"
    };
</script>

<style lang="scss" scoped>

</style>
