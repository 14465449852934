<template>
    <div :class="`event-indiv ${image ? 'with-img' : ''}`">
    
        <div v-if="this.objNotEmpty(image)" class="thumb">
            <a :href="permalink"><PostImage :image="image" /></a>
        </div>

        <div class="info">
            <div class="tag" v-html="tag" />
            <h3><a :href="permalink" v-html="title" /></h3>
        </div>
    
        <div class="meta">
            <div class="date" v-html="date" />
        </div>

    </div>
</template>

<script type="application/javascript">
    import PostImage from "./PostImage.vue";
    import mixins from "../../Util/mixins.js";
    
    export default {
        props: {
            date: String,
            image: {
                type: [Array, Object],
                default: null,
            },
            permalink: String,
            tag: String,
            title: String,
        },
        data: () => ({}),
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {},
        methods: {},
        computed: {},
        watch: {},
        components: {PostImage},
        mixins: [mixins],
        name: "Post"
    };
</script>

<style lang="scss" scoped>

</style>
