import axios from "axios";

/**
 * API call to grab the navigation items from the Rest API
 * @param store
 * @param opts
 * @returns {Promise<AxiosResponse<any> | void>}
 */
export function getNavigation(store, opts) {
    return axios
        .get(
            NAV.api,
            {
                params: {
                    navLocation: opts.navLocation,
                    viewport: opts.viewport
                }
            }
        )
        .then(({data, status}) => {
            if (status === 200 && data.status !== 404) {
                store.commit("buildNavigation", {
                    viewport: opts.viewport,
                    data: data || null,
                });
            }
        })
        .catch(err => console.error(err));
}

/**
 * Makes an API call to grab posts using pagination
 *
 * @param store Vuex store state
 * @param api   API Url, defaults to store state
 * @param paged API Paged, defaults to store state
 * @param query API Query, defaults to store state
 * @returns {boolean|Promise<*>}
 */
export function getPagedItems(store, {api = null, paged = null, query = null}) {
    store.commit("setState", {loading: true});
    
    if (!api) api = store.state.apiUrl;
    if (!paged) paged = store.state.paged;
    if (!query) query = store.state.query;
    
    if (!api && !paged) return false;
    
    return axios
        .post(
            `${api}/${paged}`,
            query,
        )
        .then(({data, status}) => {
            if (status === 200 && data.status !== 400) {
                const {maxPages, posts, total} = data;
                store.commit("itemList", {
                    posts: posts,
                });
                store.commit("setState", {
                    maxPages: maxPages,
                    total: total
                });
            }
        })
        .catch(err => console.error(err))
        .finally(() => store.commit("setState", {loading: false}));
}

