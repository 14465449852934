export default {
    methods: {
        isActive(postId, id) {
            return postId === id ? "active" : "";
        },
        listItemClass(postId, id, classes, children = 0) {
            let returnClass = "";
            classes ? returnClass += `${classes}` : null;
            children.length ? returnClass += ` has-children` : null;
            postId === id ? returnClass += " current-page" : null;
            return returnClass;
        },
        objNotEmpty(obj) {
            return Object.keys(obj).length !== 0;
        },
        objHasKey(obj, key) {
            return obj.hasOwnProperty(key);
        },
        isInArray(array, item) {
            return array.contains(item);
        },
        indexItem(array, item) {
            return array.indexOf(item);
        },
        /**
         * This function converts a string to a slug
         * @param str
         * @returns {string}
         */
        slugText(str) {
            return str
                    .toLowerCase()
                    .trim()
                    .replace(/[^\w\s-]/g, '')
                    .replace(/[\s_-]+/g, '-')
                    .replace(/^-+|-+$/g, '')
        },
        /**
         * This event handles the copy to clipboard functionality
         * @param copyText
         * @returns {Promise<void>|void}
         */
        copyText(copyText) {
            if (copyText) {
                if (navigator.clipboard) {
                    return navigator.clipboard
                             .writeText(copyText)
                } else if (window.clipboardData) {
                    return window.clipboardData
                          .setData("Text", copyText)
                }
            }
        },
        /**
         * This handles the events after clicking the copied item
         * @param linkCopiedElm
         */
        copiedEvents(linkCopiedElm) {
            if (linkCopiedElm) {
                const copiedClassList = linkCopiedElm.classList;
                copiedClassList.add('visible');
                // Can adjust this timing as needed or add a fade transition if desired
                setTimeout(() => copiedClassList.remove('visible'), 2000);
            }
        },
        /**
         * This function is a quick call to a scroll event
         * @param jqueryElm
         */
        scrollToEvent(jqueryElm) {
            $("html, body").animate({
                scrollTop: jqueryElm.offset().top
            }, 1000);
        },
        /**
         * Quick method to call to $store.commit setState
         * with an object
         * @param {object} obj
         */
        commitState(obj) {
            this.$store.commit("setState", obj);
        }
    },
};
